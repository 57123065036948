import {defaultEnvironment, Environment} from './default-environment';

export const environment: Environment = {
  ...defaultEnvironment,
  name: 'prod',
  sickIdUrl: 'https://id.sick.com/',
  oidc: {
    ...defaultEnvironment.oidc,
    issuer: 'https://id.sick.com/auth/realms/sickservices'
  },
  url: {
    aws: `https://guhkljvq9d.execute-api.eu-central-1.amazonaws.com/prod/app`,
    assets: `https://assets.flowsic-applicationdesign.cloud.sick.com/`,
    flowTwin: 'https://flowsic-applicationdesign.cloud.sick.com/'
  },
  production: true,
  initializeSentry: true
};
//
